import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import HstreamLogo from '../HstreamLogo';
import AssistanceImage from '../../assets/conversation.svg';

const AssistanceModal = ({ displayAssistance, toggleDisplayAssistance }) => {
  return (
    <Modal isOpen={displayAssistance} toggle={toggleDisplayAssistance}>
      <ModalHeader
        style={{
          backgroundColor: '#F5F7FA',
          borderBottom: '1px solid #F5F7FA',
        }}
        toggle={toggleDisplayAssistance}
      >
        Contact Us
      </ModalHeader>
      <ModalBody>
        <div className="assistanceFlex">
          <div>
            <div style={{ width: '150px', height: '33px'}}>
              {<HstreamLogo />}
            </div>
            <div className="question">Need Assistance?</div>
            <p className='phone'>Phone: (800) 521-0574</p>
            <p className='showOnMobile'>Phone: <a href="tel:1-800-521-0574" className='test_href' >(800) 521-0574</a></p>
            <p className='customerServiceInfo testHour'>Support hours: Mon - Fri 7AM - 7PM CST</p>
            <p className='customerServiceInfo testHoliday'>Closed Holidays</p>
          </div>
          <img
            style={{ height: '157px', width: '140px' }}
            className="assistanceImage"
            src={AssistanceImage}
            alt=""
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

AssistanceModal.propTypes = {
  toggleDisplayAssistance: PropTypes.func.isRequired,
  displayAssistance: PropTypes.bool.isRequired,
};

export default AssistanceModal;
