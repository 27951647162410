import React from 'react'
import { Link } from 'react-router-dom';
import AuthWrapper from '../../components/AuthWrapper'

const NeedHelp = () => {


    return (
            <AuthWrapper
            >
                <div className='p-3'>
                    <strong className='mb-1 testStrongTag_id'>Think you have an hStream account already?</strong>
                    <p className='my-3 testPTag1_id'>
                        If you think you have an hStream account, try logging in with
                        any email address or mobile number associated with your
                        account or contact us for help. We’d like to help you recover
                        your account.
                    </p>
                    <p className='my-4 testPTag2_id'>
                        Creating another account is not advised.
                    </p>
                    <strong className='testStrongTag2_id'>Need Assistance?</strong>
                        <p className='m-0 d-none d-sm-block testPTagPhone_id'>Phone: (800) 521-0574</p>
                        <p className='m-0 d-block d-sm-none'>Phone: <a href='tel:1-800-521-0574' className='mobileLink_id'>(800) 521-0574</a></p>
                        <p className='m-0 testPTagHours_id'>Support hours: Mon - Fri 7AM - 7PM CST</p>
                        <p className='m-0 testPTagHolidays_id'>Closed Holidays</p>           
                    <Link to='/login' className='btn btn-primary btn-block mt-5' id='testLink_id'>Return to Sign in</Link>
                </div>
            </AuthWrapper>
    )
}

export default NeedHelp