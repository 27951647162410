import React, { useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import AuthWrapper from '../AuthWrapper';

const TestHstreamWidget = ({ handleSubmit }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        applicationId: '',
        appUserId: '',
        appAffiliationId: '',
        accessToken: '',
    });

    const handleLookupClick = async () => {
        if (window.hstream && typeof window.hstream.search === 'function') {
            window.hstream.search({
                firstName: formData.firstName,
                lastName: formData.lastName,
                applicationId: formData.applicationId,
                email: formData.email?.trim(),
                appUserId: formData.appUserId,
                appAffiliationId: formData.appAffiliationId,
                accessToken: formData.accessToken,
            });
        } else {
            console.error('hstream.search is not available.');
        }
    };

    const handleInputChange = (e, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleLookupClick();
    };

    return (
        <div>
            <AuthWrapper>
                <Form onSubmit={onSubmit}>
                    <div className="pb-2">
                        <label>First Name:</label>
                        <Input
                            type="text"
                            value={formData.firstName}
                            onChange={(e) => handleInputChange(e, 'firstName')}
                        />
                    </div>
                    <div className="pb-2">
                        <label>Last Name:</label>
                        <Input
                            type="text"
                            value={formData.lastName}
                            onChange={(e) => handleInputChange(e, 'lastName')}
                        />
                    </div>
                    <div className="pb-2">
                        <label>Email Address:</label>
                        <Input
                            type="text"
                            value={formData.email}
                            onChange={(e) => handleInputChange(e, 'email')}
                        />
                    </div>
                    <div className="pb-2">
                        <label>Application ID:</label>
                        <Input
                            type="text"
                            value={formData.applicationId}
                            onChange={(e) => handleInputChange(e, 'applicationId')}
                        />
                    </div>
                    <div className="pb-2">
                        <label>App User ID:</label>
                        <Input
                            type="text"
                            value={formData.appUserId}
                            onChange={(e) => handleInputChange(e, 'appUserId')}
                        />
                    </div>
                    <div className="pb-2">
                        <label>App Affiliation ID:</label>
                        <Input
                            type="text"
                            value={formData.appAffiliationId}
                            onChange={(e) => handleInputChange(e, 'appAffiliationId')}
                        />
                    </div >
                    <div className="pb-3">
                        <label>AccessToken:</label>
                        <Input
                            type="text"
                            value={formData.accessToken}
                            onChange={(e) => handleInputChange(e, 'accessToken')}
                        />
                    </div>
                    <div>
                        <Button color="primary" className="btn btn-block" type="submit">Lookup User</Button>
                    </div>
                </Form>
            </AuthWrapper>
        </div>
    );
}

export default TestHstreamWidget;
