import axios from 'axios';
import qs from 'qs';
import newRelic from '../helpers/newRelic';

let identityClientBasePath = '';
let hstreamElement = document.getElementById('hstream');

if (hstreamElement) {
    identityClientBasePath = hstreamElement.getAttribute('src').replace('widgetclient.js', '');
}
const _axios = axios.create({ baseURL: identityClientBasePath });

const identityClientConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const identityAuthCodeFlowConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};


const identityClient = {
  async setAccountPreference(preference, value, queryString) {
    try{
        await axios.post(
          `/v2/oauth2/setaccountpreference${queryString}`,
          { preference, value },
          identityClientConfig,
        )
    }
    catch(err){
      newRelic.noticeError(err);
      throw err;
    }
  },

  async addPhoneNumber(phone, queryString) {
    try{
      await axios.post(
        `/v2/oauth2/addphonenumber${queryString}`,
        { phone },
        identityClientConfig,
      )
      return;
  }
  catch(err) {
    newRelic.noticeError(err);
    throw err;
  }
  },

  async sendVerificationCode(phone, email, verificationMethod) {
    try{
    const resp = await this.getCurrentSession();
      identityClientConfig.headers['Authorization'] = resp.data.accessToken;
      await axios.post(
        '/api/sendVerification',
        {
          message: {
            phone,
            verificationMethod,
            email,
            hstreamId: resp.data.hstreamId,
          },
        },
        identityClientConfig,
      )
      return;
    }
    catch(err) {
      newRelic.noticeError(err);
      throw err;
    }
  },

  
async verifyPhone(username, verification) {
  const payload = {
    hstreamId: username,
    code: verification,
  };
  try{
    await axios.post(
      `/api/confirm`,
      JSON.stringify(payload),
      identityClientConfig,
    )
    return;
}
catch(err) {
  newRelic.noticeError(err);
  throw err;
}},

  async myAccount(queryString) {
    try{
    const response = await axios.get(
      `/v2/oauth2/getAccount${queryString}`,
      identityClientConfig,
    )
    return response.data;
    }
    catch(err){
      newRelic.noticeError(err);
      throw err;
    }
  },

  async getAccountPreferences(queryString) {
    try{
      const response = await axios.get(
        `/v2/oauth2/getaccountpreferences${queryString}`,
          identityClientConfig,
        )
        return response.data.preferences;  
      }
    catch(err) {
          newRelic.noticeError(err);
          throw err;
        }   
  },

    async getAccountInfo(hstreamId) {
        try {
            const response = await axios.get(
                `/api/${hstreamId}/getAccount`,
                { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } },
            )
            return response.data;
        }
        catch (err) {
            newRelic.noticeError(err);
            throw err;
        }
    },

  async oauthGetLogin(queryString) {
    const response = await axios.get(`/v2/Oauth2/Login${queryString}`, { ...identityAuthCodeFlowConfig, ...{ withCredentials: true } });
    identityAuthCodeFlowConfig.headers['x-xsrf-token'] = response.headers['x-xsrf-token'];
    return response;
  },

  async blindLogin(queryString) {
    try {
      const resp = await axios.post(
        `/v2/Oauth2/ValidateUsername${queryString}`,
        qs.stringify({
          username: null,
        }),
        {
          ...identityAuthCodeFlowConfig,
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
        },
      );
      window.location = resp.headers.location;
      return;
    } catch (err) {
      if (
        err.response
        && (err.response.status === 401 || err.response.status === 405 || err.response.status === 400)
      ) {
        // if we fail due to no valid cookie, we want to indicate to continue
        // with cookie retrieval
        return;
      }
      // this will get caught and logged by the calling method
      throw err;
    }
  },

    async validateUsername(username, queryString) {

        try {
            const resp = await axios.post(
                `/v2/oauth2/ValidateUsername${queryString}`,
                qs.stringify({
                    username: username
                }),
                {
                    ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } }

                },
            );

            if (resp.headers.location) {
                window.location = resp.headers.location;
            }

            return resp.data;

        }
        catch (err) {
            throw err;
        }
    },

    async oauthLogin(username, password, queryString) {
        try {
            const resp = await axios.post(
                `/v2/oauth2/Login${queryString}`,
                qs.stringify({
                    username,
                    password,
                }),
                {
                    ...identityAuthCodeFlowConfig,
                    ...{
                        withCredentials: true,
                        headers: {
                            ...identityAuthCodeFlowConfig.headers,
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    },
                },
            );

            if (resp.headers.location) {
                localStorage.setItem('isMobilePrompt', 1);
                localStorage.setItem('oAuthRedirectUrl', resp.headers.location);
            }
            return resp.data;
        } catch (err) {
            throw err;
        }
    },

    async SendOtp(hstreamId, email, phone, verificationMethod, queryString) {
        try {
            const resp = await axios.post(
                `/api/auth/otp/send${queryString}`,
                {
                    message: {
                        hstreamId,
                        email,
                        phone,
                        verificationMethod 
                    }
                },
                identityClientConfig,
            );
        } catch (err) {
            throw err;
        }
    },


    async ConfirmOtp(hstreamId, verification, queryString) {
        const payload = {
            hstreamId: hstreamId,
            code: verification,
        };
        try {
          const resp = await axios.post(
                `/api/auth/otp/verify${queryString}`,
                JSON.stringify(payload),
                identityClientConfig,
            )
            if (resp.headers.location) {
              localStorage.setItem('isMobilePrompt', 1);
              localStorage.setItem('oAuthRedirectUrl', resp.headers.location);
          }
            return;

        } catch (err) {
            throw err;
        }
    },

  async fetchCustomBrandingData(clientId) {
    try {
        const resp = await axios.get(`/api/${clientId}/getcustombranding`, identityClientConfig);
        return resp.data;
    } catch (err) {
        newRelic.noticeError(err);
        throw err;
    }
  },

    async recoverPrimaryEmail(email){
      try {
        const response = await axios.get(`/v2/Oauth2/recoveraccount?secondaryContact=${email}`, { ...identityAuthCodeFlowConfig });
        return response.data;
    } catch (err) {
        newRelic.noticeError(err);
        throw err;
    }
    },

  //this will call getCurrentSession Api method to check csrf cookie session
  async getCurrentSession() {
    try {
        const resp = await axios.get(`/v2/Oauth2/getCurrentSession`,
      {
        ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
        ...identityAuthCodeFlowConfig,
      });
      localStorage.setItem('accessTokenId',resp.data.accessToken);
      return resp;
    } catch (err) {
     newRelic.noticeError(err);
      throw err;
    }
  },

  //this will call logOut Api method to get the current user logged out and will clear current user session
    async logOut(response_type, client_id) {
      try {
        let endpointUrl = `/v2/Oauth2/logout?response_type=access`
        if(client_id != null && client_id != undefined && response_type != null && response_type != undefined)
        {
          endpointUrl = `/v2/Oauth2/logout?response_type=${response_type}&client_id=${client_id}`        
        }
        else if(client_id != null && client_id != undefined)
        {          
          endpointUrl = `/v2/Oauth2/logout?client_id=${client_id}`
        }
        else if (response_type != null && response_type != undefined){
          endpointUrl = `/v2/Oauth2/logout?response_type=${response_type}`
        }
        const resp = await axios.get(
          endpointUrl,
          {
            ...identityAuthCodeFlowConfig,
            ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers } },
          },
        );
        return resp;
      } catch (err) {
        throw err;
      }
    },

  async oAuthClientSignOut(logout_uri, client_id) {
    try {
    let endpointUrl = `/v2/Oauth2/logout?client_id=${client_id}`
    if(logout_uri)
    endpointUrl = `/v2/Oauth2/logout?client_id=${client_id}&logout_uri=${logout_uri}`
      const response = await axios.get(
        endpointUrl,
        {
          ...{ withCredentials: true, headers: { ...identityAuthCodeFlowConfig.headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } } },
          ...identityAuthCodeFlowConfig,
        }
      );
     return response;
    } catch (err) {
      throw err;
    }
    },

    async findHstreamAccounts(firstName, lastName, applicationId, email, appUserId, appAffiliationId, accessToken) {
        identityClientConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        return _axios.post(
            '/api/linkingaccounts/findhstreamaccounts',
            {
                firstname: firstName,
                lastname: lastName,
                applicationId: applicationId,
                email: email,
                appUserId: appUserId,
                appAffiliationId: appAffiliationId,
            },
            identityClientConfig,
        );

    }, 

    async linkAccountsSendVerificationCode(referenceId, destination, hstreamId, appUserId, appAffiliationId, accessToken, applicationId) {
        identityClientConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        return _axios.post(
            '/api/LinkingAccounts/sendverificationcode',
            {
                hStreamId: hstreamId,
                verificationMethod: "email",
                destination: destination,
                referenceId: referenceId,
                applicationId: applicationId,
                appUserId: appUserId,
                appAffiliationId: appAffiliationId,
            },
            identityClientConfig,
        );

    },

    async linkAccountsVerifyCode(referenceId, destination, code, hstreamId, accessToken) {

        identityClientConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        return _axios.post(
            '/api/LinkingAccounts/verifycode',
            {
                hStreamId: hstreamId,
                code: code,
                destination: destination,
                referenceId: referenceId,
            },
            identityClientConfig,
        );

    },
    async linkAccountsResendCode(referenceId, destination, hstreamId, accessToken) {

        identityClientConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        return _axios.post(
            '/api/LinkingAccounts/resendcode',
            {
                hStreamId: hstreamId,
                destination: destination,
                referenceId: referenceId,
            },
            identityClientConfig,
        );

    },
    async linkAccountsRegister(firstName, lastName, email, password, referenceId, hstreamId, accessToken) {
        identityClientConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        return _axios.post(
            '/api/linkingaccounts/register',
            {
                firstName: firstName,
                lastName: lastName,
                password: password,
                email: email,
                referenceId: referenceId,
                hStreamId: hstreamId
            },
            identityClientConfig,
        );
    },
   
};

export default identityClient;

export { identityClientConfig, identityAuthCodeFlowConfig };
