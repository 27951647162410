import axios from 'axios';

async function loadConfig() {
    try {
        const fetchUrl = `/api/loadConfig`;
        return axios.get(fetchUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((data) => { return data.data;})
    } catch (error) {
        throw new Error('Error loading config');
    }
}

export default loadConfig;
