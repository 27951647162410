import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner, Button, Alert } from 'reactstrap';
import * as selectors from 'redux-modules/auth/selectors';
import AuthWrapper from 'components/AuthWrapper';
import qs from 'qs';
import {
  verifyRegistration,
  resendConfirmationCode,
} from 'redux-modules/auth/actions';
import redirect from 'helpers/redirect';
import { Redirect } from 'react-router-dom';
import locationShape from 'shapes/locationShape';
import AuthWrapperSecondary from '../Verify/AuthWrapperSecondary';
import Success from '../Verify/Success';

class AutoVerify extends Component {
  state = {
    payload: '',
    code: undefined,
    phone: undefined,
    email: undefined,
  };

  constructor(props) {
    super(props);
    const { search } = this.props;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { p, c, phone, email } = params;
    this.state.payload = p;
    this.state.code = c;
    this.state.phone = phone;
    this.state.email = email;
    this.state.loading = false;
  }

  handleVerifyConnect = () => {
    try {
      this.setState({
        loading: true,
      });
      const { payload, code } = this.state;
      const { verifyRegistrationConnect } = this.props;
      if (code !== null && code !== undefined && code.trim() !== '') {
        verifyRegistrationConnect(payload, code);
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  resendCode = (method) => {
    const { payload } = this.state;
    const { resendConfirmationCodeConnect, clientId, userPoolId } = this.props;
    const user = {
      username: payload,
      clientId,
      userPoolId,
      verificationFlow: {
        method,
        address: payload,
      },
    };
    resendConfirmationCodeConnect(user);
    this.setState({
      loading: true,
    });
  };

  render() {
    const {
      location,
      completedRegistrationStepTwo,
      errorMessage,
      wasConfirmationCodeRedelivered,
    } = this.props;

    const { code, payload, phone, email, loading } = this.state;
    
    const userIsAlreadyConfirmed = errorMessage
      ? errorMessage.includes('Current status is CONFIRMED')
      : false;

    const redirectName = redirect.parseRedirectName(location);

    if (wasConfirmationCodeRedelivered === true) {
      return (
        <Redirect
          push
          to={{
            pathname: '/confirm',
            state: {
              username: payload,
              autoVerifyResentCode: true,
            },
          }}
        />
      );
    }

    if (errorMessage && !userIsAlreadyConfirmed) {
      var displayErrorMessage = errorMessage.replace(/code/g, "link");
      return (
        <AuthWrapper
          title="Verify your hStream ID"
          redirectName={redirectName}
          secondary={<AuthWrapperSecondary />}
        >
          {errorMessage != 'An error occurred trying to verify your hStream ID.' ? (
            <Alert color="danger">
              {displayErrorMessage}
            </Alert>
          ) : (
            <Alert color="danger">
              Something went wrong while verifying your account.{' '}
              <a href={`/confirmUser?p=${payload}&c=${code}`}>Try again.</a>
            </Alert>
          )}
          <div className="AuthWrapper__content-message">
            <hr />
          </div>
        </AuthWrapper>
      );
    }
    return (
      <AuthWrapper
        title="Verify your hStream ID"
        redirectName={redirectName}
        secondary={<AuthWrapperSecondary />}
      >
        {completedRegistrationStepTwo || userIsAlreadyConfirmed ? (
          <Success toSignIn={{ pathname: '/login' }} />
        ) : loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <h4>Almost done.</h4>
            <p style={{ fontSize: 'medium' }}>Click to confirm your account.</p>
            <Button onClick={this.handleVerifyConnect} color="success" block>
              Confirm
            </Button>
          </>
        )}
        {!code && (
          <Redirect
            push
            to={{
              pathname: '/confirm',
              state: {
                username: payload,
                email: phone || email,
                limitResendOption: true,
              },
            }}
          />
        )}
      </AuthWrapper>
    );
  }
}

AutoVerify.propTypes = {
  clientId: PropTypes.string.isRequired,
  userPoolId: PropTypes.string.isRequired,
  wasConfirmationCodeRedelivered: PropTypes.bool,
  resendConfirmationCodeConnect: PropTypes.func.isRequired,
  verifyRegistrationConnect: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  location: locationShape.isRequired,
  completedRegistrationStepTwo: PropTypes.bool,
  errorMessage: PropTypes.string,
};

AutoVerify.defaultProps = {
  completedRegistrationStepTwo: false,
  errorMessage: undefined,
  wasConfirmationCodeRedelivered: false,
};

const mapStateToProps = (state) => ({
  errorMessage: selectors.getErrorMessage(state),
  clientId: selectors.getClientId(state),
  userPoolId: selectors.getUserPoolId(state),
  search: state.router.location.search,
  completedRegistrationStepTwo: selectors.completedRegistrationStepTwo(state),
  wasConfirmationCodeRedelivered: state.auth.wasConfirmationCodeRedelivered,
});

const mapDispatchToProps = {
  verifyRegistrationConnect: verifyRegistration,
  resendConfirmationCodeConnect: resendConfirmationCode,
};

const ConnectedAutoVerify = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoVerify);

export default ConnectedAutoVerify;
export { AutoVerify as PureAutoVerify };
