import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Spinner } from 'reactstrap';
import {
  forgotPassword,
  forgotPasswordSubmit,
  togglePasswordVisibility,
  forceResetPasswordSubmit,
} from 'redux-modules/auth/actions';
import * as selectors from 'redux-modules/auth/selectors';
import AuthWrapper from 'components/AuthWrapper';
import { normalizePhone } from 'components/fields/PhoneNumberField';
import locationShape from 'shapes/locationShape';
import qs from 'qs';
import Success from './Success';
import SendResetLink from './SendResetLink';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import newRelic from '../../helpers/newRelic';
import errorMessages from '../../helpers/errorMessages';

class ResetPassword extends Component {
  state = {
    loading: true,
    resetParams: undefined,
    resetFlow: false,
    location: undefined,
    username: false,
    PasswordComplexityFeature: false,
    forceResetPassword: false,
    telecom:undefined,
  };

  constructor(props) {
    super(props);

    try {
      const { location, PasswordComplexityFeature } = props;
      const params = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const { hstreamid, code } = params;
      if (hstreamid && code) {
        this.state.resetParams = { hstreamid, code };
      }

      this.state.location = location;
      this.state.PasswordComplexityFeature = PasswordComplexityFeature;
      if(location.state != undefined || location.state != null)
      {
        this.state.username = true;
        if(location.state.forceResetPassword != undefined)
        {
          this.state.forceResetPassword = location.state.forceResetPassword;
        }
        if(location.state.telecom != undefined)
        {
          this.state.telecom = location.state.telecom;
        }
      }
      this.state.loading = false;
    } catch (err) {
      newRelic.noticeError(err);
      this.setState({
        loading: false,
      });
    }
  }

  onSubmit = (values) => {
    values.hstreamid = values.hstreamid.trim();
    if (values.hstreamid.indexOf('@') === -1) {
      values.hstreamid = normalizePhone(values.hstreamid);
    }
    this.props.forgotPasswordConnect(values.hstreamid, window.location.search);
  };

  onConfirmationCode = (values) => {
    const { password } = values;
    const { forgotPasswordSubmitConnect } = this.props;
    const { forceResetPasswordSubmitConnect } = this.props;
    const { resetParams } = this.state;
    
    forgotPasswordSubmitConnect(
      resetParams.hstreamid,
      resetParams.code,
      password,
    );
     };

  onForceResetPasswordSubmit = (values) => {
    const {currentPassword}= values;
    const { password } = values;
    const { forceResetPasswordSubmitConnect } = this.props;
    const { resetParams } = this.state;
    
    forceResetPasswordSubmitConnect(
      this.state.telecom,
      currentPassword,
      password,
     
    );
  };

  handleSendNewCode = () => {
    this.setState({
      resetFlow: true,
    });
  };

  removeVerificationIdentifiers = () => {
    const { location } = this.state;
    const parsedQueryString = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    delete parsedQueryString.hstreamid;
    delete parsedQueryString.code;
    return qs.stringify(parsedQueryString, { encode: false });
  };

  render() {
    const {
      showLoader,
      errorMessage,
      isPasswordVisible,
      isRequestPending,
      togglePasswordVisibilityConnect,
      forgotPasswordSubmitSuccess,
      forceResetPasswordSubmitSuccess,
    } = this.props;
    let {isForgotPasswordSent, forgotPasswordUsername} = this.props;

    const { loading, resetParams, resetFlow, location, username, PasswordComplexityFeature, forceResetPassword } = this.state;
    let isEmailOrPhone;
    if(forgotPasswordUsername)
    isEmailOrPhone =(forgotPasswordUsername.indexOf('@') === -1)? "phone number": "email";
    if (username)
    {
        isForgotPasswordSent = true;
        forgotPasswordUsername = location.state.username;
    }

    const toSignIn = {
      pathname: '/login',
      search: location.search,
    };
    if (resetFlow) {
      location.search = this.removeVerificationIdentifiers(location.search);
      this.setState({
        location,
        resetParams: undefined,
        resetFlow: false,
      });
    }

    if (loading) {
      return (
        <AuthWrapper title="Forgot Password">
          <Spinner color="primary" size="lg" />
        </AuthWrapper>
      );
    }

    return (
      <AuthWrapper title={resetParams ? 'Reset Password' : username? 'Reset Password' : 'Forgot Password'}>
       {showLoader && <div className="d-flex justify-content-center mt-5" style={{ minHeight: '50px' }}>
        <Spinner color="primary" />
      </div>
      }
        {!isRequestPending && errorMessage && (
          <Alert color="danger">
            {errorMessage}{' '}
            {(errorMessage === errorMessages.expiredVerificationLinkError.message) && (<Link
              to={`/forgotPassword?${this.removeVerificationIdentifiers(
                location.search,
              )}`}
              onClick={this.handleSendNewCode}
              style={{
                fontSize: 'inherit',
                padding: 'unset',
                verticalAlign: 'unset',
              }}
            >
              Send a new verification link.
            </Link>)}
          </Alert>
        )}

      {forceResetPassword && !forceResetPasswordSubmitSuccess && (
           <ConfirmPasswordForm
           email={forgotPasswordUsername}
           displayCurrentPassword={true}
           isPasswordVisible={isPasswordVisible}
           onPasswordToggle={togglePasswordVisibilityConnect}
           isRequestPending={isRequestPending}
           PasswordComplexityFeature= {PasswordComplexityFeature}
           onSubmit={this.onForceResetPasswordSubmit}
         />
        )}

        {!isForgotPasswordSent && !resetParams && !this.state.forceResetPassword &&(
          <SendResetLink
            isRequestPending={isRequestPending}
            onSubmit={this.onSubmit}
            queryString={location.search}
          />
        )}
        
        {isForgotPasswordSent && !this.state.forceResetPassword &&(
          <section>
            <p id="confirmation-msg" style={{ marginBottom: '2rem' }}>
              A password reset link has been sent to{' '}
              <strong>{forgotPasswordUsername}</strong>, if the{' '}{isEmailOrPhone}{' '}exists.
            </p>
             <hr />
            <section className="d-flex align-items-center">
              <span style={{ marginRight: '.5em' }}>Ready to sign in?</span>
              <Link to={toSignIn}>Sign In</Link>
            </section>
            </section>
              
        )}


        {resetParams && !forgotPasswordSubmitSuccess && (
          <section>
            <ConfirmPasswordForm
              email={forgotPasswordUsername}
              isPasswordVisible={isPasswordVisible}
              onPasswordToggle={togglePasswordVisibilityConnect}
              isRequestPending={isRequestPending}
              onSubmit={this.onConfirmationCode}
              PasswordComplexityFeature= {PasswordComplexityFeature}
            />
          </section>
        )}

        {forgotPasswordSubmitSuccess && <Success queryString={location.search}/>}
        {forceResetPasswordSubmitSuccess && <Success queryString={location.search}/>}
      </AuthWrapper>
    );
  }
}

ResetPassword.propTypes = {
  showLoader: PropTypes.bool,
  forgotPasswordUsername: PropTypes.string,
  forgotPasswordSubmitSuccess: PropTypes.bool,
  forceResetPasswordSubmitSuccess:PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  isForgotPasswordSent: PropTypes.bool,
  isPasswordVisible: PropTypes.bool,
  forgotPasswordConnect: PropTypes.func.isRequired,
  forgotPasswordSubmitConnect: PropTypes.func.isRequired,
  forceResetPasswordSubmitConnect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  togglePasswordVisibilityConnect: PropTypes.func.isRequired,
  location: locationShape.isRequired,
};

ResetPassword.defaultProps = {
  showLoader: false,
  forgotPasswordUsername: undefined,
  forgotPasswordSubmitSuccess: false,
  forceResetPasswordSubmitSuccess:false,
  isForgotPasswordSent: false,
  isPasswordVisible: false,
  errorMessage: undefined,
};

const mapStateToProps = (state) => ({
  showLoader: selectors.showLoader(state),
  forgotPasswordUsername: selectors.getForgotPasswordUsername(state),
  forgotPasswordSubmitSuccess: selectors.getForgotPasswordSubmitSuccess(state),
  forceResetPasswordSubmitSuccess: selectors.getForceResetPasswordSubmitSuccess(state),
  isPasswordVisible: selectors.isPasswordVisible(state),
  isForgotPasswordSent: selectors.isForgotPasswordSent(state),
  isRequestPending: selectors.isRequestPending(state),
  errorMessage: selectors.getErrorMessage(state),
});

const mapDispatchToProps = {
  forgotPasswordConnect: forgotPassword,
  forgotPasswordSubmitConnect: forgotPasswordSubmit,
  forceResetPasswordSubmitConnect: forceResetPasswordSubmit,
  togglePasswordVisibilityConnect: togglePasswordVisibility,
};

const ConnectedResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);

export default ConnectedResetPassword;
export { ResetPassword as PureResetPassword };
