import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import userShape from 'shapes/userShape';
import locationShape from 'shapes/locationShape';
import { getUser } from 'redux-modules/auth/selectors';
import session from 'helpers/session';

// export const ROUTE_DEFAULT = '/welcome';
// export const ROUTE_DEFAULT = '/sign-in';
export const ROUTE_DEFAULT = '/login';

const ProtectedRoute = ({ component: Component, user, location, ...rest }) => {
  const { search } = location;
  const toDefault = {
    pathname: ROUTE_DEFAULT,
    search,
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && user.isRegistered) {
          session.check();
          return <Component {...props} {...rest} />;
        }
        return <Redirect push to={toDefault} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(React.Component),
  ]).isRequired,
  user: userShape,
  location: locationShape.isRequired,
};

ProtectedRoute.defaultProps = {
  user: undefined,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const ConnectedProtectedRoute = connect(mapStateToProps)(ProtectedRoute);

export default ConnectedProtectedRoute;
export { ProtectedRoute as PureProtectedRoute };
