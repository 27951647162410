import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Spinner, Alert } from 'reactstrap';
import AuthWrapper from '../../components/AuthWrapper';
import AuthWrapperFooter from 'components/AuthWrapperFooter';
import RadioButtonForm from '../../core/RadioButton';
import identityClient from '../../clients/identityClient';
import store from 'store';
import { maskEmail, maskPhoneNumber } from '../../utils/maskingUtils';
import FormatErrorMessage from '../FormatMessage/FormatErrorMessage';
import errorMessages from 'helpers/errorMessages';

const VerifyIdentity = () => {
    const location = useLocation();
    const history = useHistory();
    const { emails = [], phoneNumbers = [], verifyInfo, queryString } = location.state || {};
    const initialSelectedOption = phoneNumbers.length > 0
        ? phoneNumbers[0].value
        : (emails.length === 1 ? emails[0].value : null);
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    const [displayAssistance, setDisplayAssistance] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');

    const state = store.getState();
    var { appSettingVerificationCodeValidityPeriod } = state.auth.aws;

    useEffect(() => {
        if (!location.state) {
            history.replace('/login');
        }
    }, [location.state, history]);

    const toggleDisplayAssistance = (e) => {
        if (e) e.preventDefault();
        setDisplayAssistance(!displayAssistance);
    };

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const selectedEmail = emails.find((email) => email.value === selectedOption);
        const selectedPhone = phoneNumbers.find((phone) => phone.value === selectedOption);
        const email = selectedEmail ? selectedOption : '';
        const phone = selectedPhone ? selectedOption : '';
        const verificationMethod = selectedEmail ? 'email' : 'phone';
        const hstreamId = verifyInfo.hstreamId;

        try {
            const response = await identityClient.SendOtp(hstreamId, email, phone, verificationMethod, queryString);
            history.push({
                pathname: '/confirmLogin',
                search: queryString,
                state: { username: selectedOption, queryString, hstreamId, emails, phoneNumbers, verifyInfo }
            });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.status === 400 && error.response.data.title === "No Active Session") {
                setErrorStatus(errorMessages.noActiveSession.message);
            }
            else if (error.response && error.response.data) {
                setErrorStatus(error.response.data.detail);
            } else {
                setErrorStatus('We encountered an issue. You may try to sign in again, or if this error persists, please contact your administrator.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getExpirationMessage = () => {
        if (selectedOption) {
            const selectedEmail = emails.find((email) => email.value === selectedOption);
            if (selectedEmail) {
                return "The code expires in " + getValidationTimePeriod() + " after you request it. Please check your spam folder if you do not receive it.";
            }
        }
        return "The code expires in " + getValidationTimePeriod() + " after you request it.";
    };

    const getValidationTimePeriod = () => {
        let validityMessage = "";
        if ((Math.floor(appSettingVerificationCodeValidityPeriod / 60) == 1 && (appSettingVerificationCodeValidityPeriod % 60) > 0) || Math.floor(appSettingVerificationCodeValidityPeriod / 60) > 1) {
            if (Math.floor(appSettingVerificationCodeValidityPeriod / 60) == 1) {
                validityMessage = "1 hour ";
            } else {
                validityMessage = Math.floor(appSettingVerificationCodeValidityPeriod / 60) + " hours ";
            }
            appSettingVerificationCodeValidityPeriod = (appSettingVerificationCodeValidityPeriod % 60);
        }

        if (appSettingVerificationCodeValidityPeriod > 1) {
            validityMessage = validityMessage + appSettingVerificationCodeValidityPeriod + " minutes";
        } else if (appSettingVerificationCodeValidityPeriod == 1) {
            validityMessage = validityMessage + "1 minute";
        }

        return validityMessage.trim();
    }

    return (
        <>
            <AuthWrapper
                footer={<AuthWrapperFooter />}
            >
                {errorStatus && (
                    <FormatErrorMessage error={errorStatus} recoverErrorCode={undefined} recoverEmail={undefined} />
                )}
                <div>
                    <p style={{ fontSize: '16px' }}><b>Verify Your Identity</b></p>
                    <hr />
                    <p className='m-0 pb-1'><b>Request Authorization Code</b></p>
                    <p>To verify your identity, we need to send an authorization code</p>
                    {phoneNumbers.length > 0 && (
                        <div>
                            <div className="pb-1">Select a phone number</div>
                            <RadioButtonForm
                                id="phoneNumbers"
                                options={phoneNumbers.map((phone) => ({ label: <b id="phone" style={{ fontSize: '13px' }}>{maskPhoneNumber(phone.value)}</b>, value: phone.value }))}
                                selectedOption={selectedOption}
                                onChange={handleOptionChange}
                            />
                        </div>
                    )}
                    {emails.length > 0 && (
                        <div className="py-2">
                            {(emails.length === 1 && phoneNumbers.length === 0) ? (
                                <p className="pl-2">{maskEmail(emails[0].value)}</p>
                            ) : (
                                    <div>
                                        <div className="pb-1">Select email address</div>
                                        <RadioButtonForm
                                           id="emails"
                                            options={emails.map((email) => ({ label: <b id="email" style={{ fontSize: '13px' }}>{maskEmail(email.value)}</b>, value: email.value }))}
                                            selectedOption={selectedOption}
                                            onChange={handleOptionChange}
                                        />
                                    </div>
                                )}
                        </div>
                    )}
                    <p>{getExpirationMessage()}</p>
                    <Button
                       id="verifyIdentityContinue"
                        className="btn"
                        block
                        color="primary"
                        type="button"
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        {isLoading ? <Spinner size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </AuthWrapper>
        </>
    );
};

export default VerifyIdentity;
