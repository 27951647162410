import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from '@redux-devtools/extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import auth from './redux-modules/auth';

export const history = createBrowserHistory();

const connectedReducer = connectRouter(history);

const rootReducer = combineReducers({
  router: connectedReducer,
  auth,
  form,
});

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
);
