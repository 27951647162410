import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import locationShape from 'shapes/locationShape';
import qs from 'qs';

class Success extends Component {
  constructor(props) {
    super(props);
    const { search } = global.location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    this.state = {
      params,
      search,
    };
  }
  render() {
    return (
  <section>
    <h2 className="AuthWrapper__content-headline">Success! You have registered.</h2>
    <div className="AuthWrapper__content-message">
      <p>You can sign in on the next screen.</p>
    </div>
    <div className="AuthWrapper__content-action">
    {this.state.params && this.state.params.response_type == 'code' && this.state.params.client_id && this.state.params.redirect_uri ? (
       <Button onClick={() => { window.location.href = `/v2/oauth2/authorize${this.state.search}`; } } color="primary" block> Continue </Button>
            ) : (
              <Link to={this.props.toSignIn}>
              <Button color="primary" block>
                Continue
              </Button>
            </Link>
            )}
    </div>
  </section>
);
}
}

Success.propTypes = {
  toSignIn: locationShape.isRequired,
};

export default Success;
