export const TOGGLE_PASSWORD_VISIBILITY = 'auth/TOGGLE_PASSWORD_VISIBILITY';
export const AUTH_SUBMIT = 'auth/AUTH_SUBMIT';
export const AUTH_REQUEST_BEGIN = 'auth/AUTH_REQUEST_BEGIN';
export const AUTH_REQUEST_COMPLETE = 'auth/AUTH_REQUEST_COMPLETE';
export const AUTH_REGISTER_SUCCESS = 'auth/AUTH_REGISTER_SUCCESS';
export const AUTH_ERROR = 'auth/AUTH_ERROR';
export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
export const RR_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const LOADING_CURRENT_USER = 'auth/LOADING_CURRENT_USER';
export const LOADED_CURRENT_USER = 'auth/LOADED_CURRENT_USER';
export const SHOW_LOADER = 'auth/SHOW_LOADER';
export const HIDE_LOADER = 'auth/HIDE_LOADER';
export const SET_NO_USER = 'auth/SET_NO_USER';
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS';
export const REDO_FORGOT_PASSWORD = 'auth/REDO_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SENT = 'auth/FORGOT_PASSWORD_SENT';
export const FORGOT_PASSWORD_SUBMIT_SUCCESS = 'auth/FORGOT_PASSWORD_SUBMIT_SUCCESS';
export const FORCE_RESET_PASSWORD_SUBMIT_SUCCESS= 'auth/FORCE_RESET_PASSWORD_SUBMIT_SUCCESS';
export const CHANGE_USER_ID_SUCCESS = 'auth/CHANGE_USER_ID_SUCCESS';
export const CONFIRM_USER_ID_SUCCESS = 'auth/CONFIRM_USER_ID_SUCCESS';
export const AUTH_CODE_RESENT = 'auth/AUTH_CODE_RESENT';
export const UPDATE_USER_ATTRIBUTES = 'auth/UPDATE_USER_ATTRIBUTES';
export const RECEIVED_AWS_CREDENTIALS = 'auth/RECEIVED_AWS_CREDENTIALS';
export const REGISTRATION_SUCCESS = 'auth/REGISTRATION_SUCCESS';
export const SET_ERROR = 'auth/SET_ERROR';
export const USER_ARRIVED_SIGNED_IN = 'auth/USER_ARRIVED_SIGNED_IN';
export const USER_DID_NOT_ARRIVE_SIGNED_IN = 'auth/USER_DID_NOT_ARRIVE_SIGNED_IN';
export const O_AUTH_ERROR = 'oauth/ERR_PERFORMING_O_AUTH';
export const PROMPT_FOR_PHONE = 'auth/PROMPT_FOR_PHONE';
export const SIGNIN_ERROR = 'auth/SIGNIN_ERROR';
