import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, Form, FormGroup, Alert, Spinner } from 'reactstrap';
import HStreamIDField from 'components/fields/HStreamIDField';
import locationShape from 'shapes/locationShape';
import LabelwithLoginInformationTooltip from '../../components/Utilities/LabelWithInformationTooltip';
import HpaStoreContext from '../../contexts/HpaStoreContext';

const SignInForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    location,
    rememberMeValues,
    isLoading,
  } = props;
  const { display, disable } = rememberMeValues;
  const context = useContext(HpaStoreContext);

  const tooltipContent = [
    'If you verified by phone, login with your phone number.',
    'If you verified by email, login with your email.',
  ];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <HStreamIDField
        name="username"
        id="hStream_login_id"
        disabled={isLoading}
        label={
          <LabelwithLoginInformationTooltip
            labelText="Email or Mobile Number"
            tooltipId="sign_in_tt"
            tooltipContent={tooltipContent}
          />
        }
      />

      <FormGroup>
        <Button
          className="btn"
          block
          color="primary"
          disabled={isLoading}
          type="submit"
        >
                  {isLoading ? <Spinner size="sm" /> : 'Continue'}
        </Button>
      </FormGroup>
      {context && context.customBranding && context.customBranding.loginInstructionText &&(
        <div>
          <Alert color="info">{context.customBranding.loginInstructionText}</Alert>
       </div>
        )}
    </Form>
  );
};

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  location: locationShape.isRequired,
  rememberMeValues: PropTypes.shape({
    display: PropTypes.bool,
    value: PropTypes.bool,
    disable: PropTypes.bool,
  }).isRequired,
};

SignInForm.defaultProps = {
    isLoading: false,
};

const ConnectedSignInForm = reduxForm({
  form: 'sign-in',
})(SignInForm);

export default ConnectedSignInForm;
export { SignInForm as PureSignInForm };
