import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Button } from 'reactstrap';
import AssistanceModal from '../../components/AssistanceModal';


const AuthWrapperFooter = () => {
    const [displayAssistance, setDisplayAssistance] = useState(false);
    const toggleDisplayAssistance = (e) => {
        if (e) e.preventDefault();
        setDisplayAssistance(!displayAssistance);
    };
    return (
        <>
        <div>
                <Button
                    id="contact-modal-trigger"
                    onClick={toggleDisplayAssistance}
                    color="link"
                >
                    Contact Us
        </Button>
                <div>
                    Copyright &copy; {Moment().year()} HealthStream, Inc.{' '}
                    <span>All Rights Reserved.</span>
                </div>
           
            </div>
            <AssistanceModal
                displayAssistance={displayAssistance}
                toggleDisplayAssistance={toggleDisplayAssistance}
            />
        </>
    );
};

AuthWrapperFooter.propTypes = {
    toggleDisplayAssistance: PropTypes.func.isRequired,
};

export default AuthWrapperFooter;
