import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AuthWrapper from 'components/AuthWrapper';
import { connect } from 'react-redux';
import * as selectors from 'redux-modules/auth/selectors';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import redirect from '../../helpers/redirect';
import newRelic from '../../helpers/newRelic';
import identityClient from '../../clients/identityClient';

const errorMessages = {
  invalid_request:
    "The application's authorization request has missing, duplicated, or unreadable information.",
  unauthorized_client:
    'The application is not permitted to request an authorization code using this method.',
  access_denied:
    "The application's authorization request has been denied access.",
  unsupported_response_type:
    "hStream ID does not support the application's authorization code using this method.",
  invalid_scope:
    'The application is requesting information from your hStream ID that does not exist, is unknown, or cannot be read.',
  server_error:
    "hStream ID encountered an unexpected error preventing the application's authorization request. You may try this request again.",
  temporarily_unavailable:
    'hStream ID is currently unable to accept authorization requests made by applications at this time due to server overload or maintenance issues.',
};

const Authorize = (props) => {
  const [authorizationError, updateAuthorizationError] = useState(undefined);
  const [loading, toggleLoading] = useState(true);
  const [redirectLocation, updateRedirectLocation] = useState(undefined);

  const handleOAuthLogin = async () => {
    const { search, state } = props.location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { error, redirect_uri } = params;
    const oauthSignInError = state ? state.oauthSignInError : false;

    if (!error && !oauthSignInError) {
      try {
        await identityClient.blindLogin(search);
        // if the above calls succeeds we will redirect from there.
        // if we make it to the next call, then we recieved an unauthroized
        // and need proceed with login.
        await identityClient.oauthGetLogin(search);
        updateRedirectLocation('/login');
      } catch (err) {
        newRelic.noticeError(err);
        updateAuthorizationError(errorMessages['server_error']);
        toggleLoading(false);
        return null;
      }
    } else if (redirect_uri) {
      // ? remove any trailing slash, append the error as querystring
      updateRedirectLocation(
        `${redirect_uri.replace(/\/$/, '')}?error=${error}`,
      );
    } else {
      const errorMessage = error
        ? errorMessages[error]
        : 'Something went wrong trying to sign you in.';
      updateAuthorizationError(errorMessage);
      toggleLoading(false);
      return null;
    }
  };

  useEffect(() => {
    handleOAuthLogin();
  }, []);

  if (redirectLocation) {
    if (redirectLocation.startsWith('http')) {
      redirect.to(redirectLocation);
    } else {
      return (
        <Redirect
          push
          to={{ pathname: redirectLocation, search: window.location.search }}
        />
      );
    }
  }

  return (
    <AuthWrapper>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {!loading && authorizationError ? (
          <div style={{ color: 'grey', padding: '2rem' }}>
            {authorizationError}
          </div>
        ) : (
          <Spinner color="primary" />
        )}
      </div>
    </AuthWrapper>
  );
};

Authorize.propTypes = {
  oauthError: PropTypes.shape({
    status: PropTypes.number,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      oauthSignInError: PropTypes.string,
    }),
    search: PropTypes.string,
  }).isRequired,
  identityBaseUrl: PropTypes.string.isRequired,
};

Authorize.defaultProps = {
  oauthError: undefined,
};

const mapStateToProps = (state) => ({
  oauthError: selectors.getOAuthError(state),
  identityBaseUrl: selectors.getIdentityBaseUrl(state),
});

const mapDispatchToProps = () => {
  return {};
};

const ConnectedAuthorize = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Authorize);

export default ConnectedAuthorize;
export { Authorize as PureAuthorize };
