import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '../FontAwesomeIcon';

function IconButton(props) {
  const {
    borderless,
    className,
    icon,
    label,
    onClick,
    style,
  } = props;

  let updatedClassname = className + " IconButton";
  if (borderless)
  updatedClassname = updatedClassname + " IconButton--borderless"
  
  return (
    <button
      type="button"
      className={
        updatedClassname
      }
      aria-label={label}
      onClick={onClick}
      style={style}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}

IconButton.propTypes = {
  borderless: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.instanceOf(Object),
};

IconButton.defaultProps = {
  borderless: false,
  className: '',
  onClick: undefined,
  style: undefined,
};

export default IconButton;
