import React, { Component } from 'react';
import HpaStoreContext from '../../contexts/HpaStoreContext';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {AnimatedSwitch}  from 'react-router-transition-lirsoft';
// const {AnimatedSwitch} = require('react-router-transition-lirsoft');
import ScrollToTop from 'components/ScrollToTop';
import AuthWrapper from 'components/AuthWrapper';
import ProtectedRoute from 'components/ProtectedRoute';
import Welcome from 'containers/Welcome';
import InactivityWarning from 'components/InactivityWarning';
import { checkCurrentUser, signOut, bypassImplicitUserCheck } from 'redux-modules/auth/actions';
import * as selectors from 'redux-modules/auth/selectors';
import redirect from 'helpers/redirect';
import SignIn from 'containers/SignIn';
import SignOut from 'containers/SignOut';
import Register from 'containers/Register';
import ResetPassword from 'containers/ResetPassword';
import NeedHelp from 'containers/NeedHelp';
import RecoverAccount from 'containers/RecoverAccount';
import Verify from 'containers/Verify';
import Dashboard from 'containers/Dashboard';
import AutoVerify from 'containers/AutoVerify';
import Authorize from 'containers/Authorize';
import switchTransition from './switchTransition';
import userShape from '../../shapes/userShape';
import { Spinner } from 'reactstrap';
import identityClient from '../../clients/identityClient';
import TestHstreamWidget from 'components/TestHstreamWidget';
import VerifyIdentity from 'containers/VerifyIdentity'


const packageJson = require('../../../package.json');

class App extends Component {
  state = {
    forcedSignIn: false,
    siteParams: {
      rememberMe: {
        display: false,
        value: true,
        disable: false,
      },
      forceSignIn: false,
      responseType: undefined,
      redirectState: undefined,
      oauthLogin: false,
    },
    loadingBranding: true,
    customBranding: undefined
  };

  constructor(props) {
    super(props);
    const { router, checkCurrentUserConnect, bypassImplicitUserCheckConnect } = props;
    const { location } = router;
    const siteParams = redirect.getSSOIntegrationOptions(location);
    if (siteParams.responseType === 'code') {
      if(!siteParams.forceSignIn)
      bypassImplicitUserCheckConnect();
    } else {
      // checking the current local user is via the implicit flow and amplify
      // which we only want to do if we aren't going through a authorization code flow
      if(!siteParams.forceSignIn)
      checkCurrentUserConnect(true); // true tells system to check if the user arrived signed in
    }
    const redirectUri = redirect.parseRedirectUri(location);
    this.state.redirectUri = redirectUri;
    this.state.siteParams = siteParams;
    const appVersion = `Version: ${packageJson.version}`;
    // eslint-disable-next-line no-console
    console.log(appVersion);
  }

  handleCustomBranding = async () => {
    try {
      const clientId = this.state.siteParams.clientId;
      if (clientId) {
        const response = await identityClient.fetchCustomBrandingData(clientId);
        this.setState({
            customBranding: response,
          });
      }
      this.setState({
        loadingBranding: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loadingBranding: false,
      });
    }
    }

  handleUserdetails = (values) => {
    const { siteParams } = this.state;
    const updatedSiteParams = Object.assign({}, siteParams);
    updatedSiteParams.username = values.email;
    updatedSiteParams.firstname = values.given_name;
    updatedSiteParams.lastname = values.family_name;
    this.setState({
      siteParams: updatedSiteParams,
    });
  };
  componentDidMount() {
    const response_type = this.state.siteParams.responseType == "code" ? "code" : "access" ;
    const client_id = this.state.siteParams.clientId;
    this.handleCustomBranding();
    if(this.state.siteParams && this.state.siteParams.forceSignIn ){
      this.props.signOutConnect(response_type,client_id);
      }
   }

  handleRememberMeChange = (value) => {
    const { siteParams } = this.state;
    const updatedSiteParams = Object.assign({}, siteParams);
    updatedSiteParams.rememberMe.value = value;

    this.setState({
      siteParams: updatedSiteParams,
    });
  };

  render() {
    const {
      isLoadingCurrentUser,
      user,
      signOutConnect,
      userArrivedSignedIn,
      config,
      promptForPhone,
    } = this.props;
    const { forcedSignIn, siteParams, redirectUri, loadingBranding } = this.state;
    let registeredUser = user && user.isRegistered;

    if (isLoadingCurrentUser) {
      return <AuthWrapper>Loading Current User</AuthWrapper>;
    }

    if (registeredUser && !promptForPhone && redirectUri) {
      const clearTokens = siteParams.rememberMe.value !== true;
      redirect.toRedirectUri(
        redirectUri,
        clearTokens,
        siteParams.responseType,
        siteParams.redirectState,
      );
      return null;
    }
    
    if (loadingBranding) {
      return <AuthWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ margin: '2em auto 2em auto' }}>
            <Spinner color="primary" />
          </div>
        </div>
      </AuthWrapper>
    }
    return (
      <HpaStoreContext.Provider value={{ customBranding: this.state.customBranding }}>
        <ScrollToTop>
          <AnimatedSwitch
            {...switchTransition}
            className="AnimatedSwitch"
            runOnMount
          >
            <Route exact path="/welcome" component={Welcome} />
            <Route path="/confirmUser" component={AutoVerify} />
            <Route
              exact
              path="/login"
              render={(routeProps) => (
                <SignIn
                  {...routeProps}
                  ssoIntegrationOptions={siteParams}
                  handleRememberMeChange={this.handleRememberMeChange}
                  username={siteParams.username}
                />
              )}
            />
            
            <Route
                exact
                path="/verifyIdentity"
                render={(routeProps) => (
                    <VerifyIdentity {...routeProps} />
                )}
            />
            <Route
              exact
              path="/needHelp"
              render={(routeProps) => (
                <NeedHelp/>
              )}
            />
            <Route
              exact
              path="/recoverAccount"
              render={(routeProps) => (
              <RecoverAccount {...routeProps} />
              )}
            />
            <Route
              exact
              path="/signup"
              render={(routeProps) => (
                <Register {...routeProps} redirectUri={redirectUri} firstName={siteParams.firstname} lastName={siteParams.lastname} email={siteParams.username} handleUserdetails={this.handleUserdetails} PasswordComplexityFeature={config.featurePasswordComplexity} />
              )}
            />
            <Route
              exact
              path="/forgotPassword"
              render={(routeProps) => (
                <ResetPassword {...routeProps} PasswordComplexityFeature={config.featurePasswordComplexity}/>
              )}
                    />
            <Route
                exact
                path="/healthstreamwidget"
                component={TestHstreamWidget}
            />
                    <Route exact path="/confirm" component={Verify} />
                    <Route exact path="/confirmLogin" component={Verify} />
          <Route
              exact
              path="/logout"
              render={(routeProps) => (
                <SignOut
                  {...routeProps}
                  redirectUri={redirectUri}
                  redirectState={siteParams.redirectState}
                  responseType={siteParams.responseType}
                  clientId={siteParams.clientId}
                />
              )}
            />
            <Route exact path="/authorize" component={Authorize} />
            <ProtectedRoute
              exact
              path="/"
              component={Dashboard}
              redirectUri={config.myhstreamUrl}
            />
            <Redirect
              path="/forgotAccount"
              to={{
                pathname: '/forgotPassword',
              }}
            />
          {/* Added Old routes but will continue with the work flow. */}
            <Redirect
              path="/account/forgot-account"
              to={{
                pathname: '/forgotPassword',
              }}
            />
            <Route path="/av" component={AutoVerify} />
            <Route
              exact
              path="/account/sign-in"
              render={(routeProps) => (
                <SignIn
                  {...routeProps}
                  ssoIntegrationOptions={siteParams}
                  handleRememberMeChange={this.handleRememberMeChange}
                  username={siteParams.username}
                />
              )}
            />
            <Route
              exact
              path="/account/need-help"
              render={(routeProps) => (
                <NeedHelp/>
              )}
            />
            <Route
              exact
              path="/account/recover-account"
              render={(routeProps) => (
              <RecoverAccount {...routeProps} />
              )}
            />
            <Route
              exact
              path="/account/register"
              render={(routeProps) => (
                <Register {...routeProps} redirectUri={redirectUri} firstName={siteParams.firstname} lastName={siteParams.lastname} email={siteParams.username} handleUserdetails={this.handleUserdetails} PasswordComplexityFeature={config.featurePasswordComplexity}/>
              )}
            />
            <Route
              exact
              path="/account/forgot-password"
              render={(routeProps) => (
                <ResetPassword {...routeProps} PasswordComplexityFeature={config.featurePasswordComplexity}/>
              )}
            />
            <Route exact path="/account/verify" component={Verify} />
          <Route
              exact
              path="/account/sign-out"
              render={(routeProps) => (
                <SignOut
                  {...routeProps}
                  redirectUri={redirectUri}
                  redirectState={siteParams.redirectState}
                  responseType={siteParams.responseType}
                  clientId={siteParams.clientId}
                />
              )}
            />
            <Route exact path="/account/authorize" component={Authorize} />
            <Redirect to={{ pathname: '/' }} />
          </AnimatedSwitch>
          {registeredUser && <InactivityWarning />}
        </ScrollToTop>
      </HpaStoreContext.Provider>
    );
  }
}

App.propTypes = {
  isLoadingCurrentUser: PropTypes.bool.isRequired,
  user: userShape,
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
  signOutConnect: PropTypes.func.isRequired,
  userArrivedSignedIn: PropTypes.bool.isRequired,
  checkCurrentUserConnect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object.isRequired,
  promptForPhone: PropTypes.bool,
};

App.defaultProps = {
  user: undefined,
  promptForPhone: undefined,
};

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
  isLoadingCurrentUser: selectors.isLoadingCurrentUser(state),
  router: state.router,
  userArrivedSignedIn: state.auth.userArrivedSignedIn,
  promptForPhone: selectors.getPromptForPhone(state),
  // NOTE not consuming router will cause this to not re-render when  route
  // change happens which can lead to broken ProtectedRoutes
});

const mapDispatchToProps = {
  checkCurrentUserConnect: checkCurrentUser,
  signOutConnect: signOut,
  bypassImplicitUserCheckConnect: bypassImplicitUserCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

export { App as PureApp };
