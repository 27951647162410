import axios from 'axios';

async function getAppsettings() {
    try {
        const fetchUrl = `/manage/appsetting`;
        return axios.get(fetchUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((data) => { 
                
                
                return data.data;})
    } catch (error) {
        throw new Error('Error loading App settings');
    }
}

export default getAppsettings;
