import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, Form, FormGroup, Spinner } from 'reactstrap';
import VerificationField from 'components/fields/VerificationField';

class VerificationCodeForm extends React.Component {
  componentDidMount() {
    const { handleSubmit, onSubmit, submitOnMount } = this.props;
    if (submitOnMount) {
      const submitFn = handleSubmit(onSubmit);
      submitFn();
    }
  }

  render() {
    const { handleSubmit, onSubmit, isRequestPending, isCancelButtonRequired, onCancel, isLoading } = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <VerificationField
          label="Verification Code"
          name="verification"
          required
        />
        <FormGroup>
          {isCancelButtonRequired?
          <div style={{display:'flex'}}><span style={{width: '60%'}}><Button disabled={isLoading} color="primary" block>            
            {isLoading ? <Spinner size="sm" /> : 'Continue'}
          </Button></span>
          <span style={{width: '40%', textAlign: 'center'}}>
            <Button disabled={isLoading}
                color="link"
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
                onClick={onCancel}
                id="cancel-application"
                >
                Cancel
                </Button></span></div> 
                : 
                <Button disabled={isRequestPending} color="primary" block>
                {isRequestPending ? <Spinner size="sm" /> : 'Continue'}
          </Button>}
          
        </FormGroup>
      </Form>
    );
  }
}

VerificationCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
  submitOnMount: PropTypes.bool,
  isCancelButtonRequired: PropTypes.bool,  
  onCancel: PropTypes.func,
};

VerificationCodeForm.defaultProps = {
  isRequestPending: false,
  submitOnMount: false,
  isCancelButtonRequired: false,
};

const ConnectedVerificationCodeForm = reduxForm({ form: 'verification' })(
  VerificationCodeForm,
);

export default ConnectedVerificationCodeForm;
export { VerificationCodeForm as PureVerificationCodeForm };
