import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';

const SendResetLink = ({ isRequestPending, onSubmit, queryString }) => (
  <section>
    <p>
      Send a reset link to the email or mobile number you use to sign in and
      we&apos;ll help you reset your password.
    </p>
    <ResetPasswordForm
      isRequestPending={isRequestPending}
      onSubmit={onSubmit}
    />
    <hr />
    <section className="d-flex align-items-center">
      <span style={{ marginRight: '.5em' }}>Ready to sign in?</span>
      <Link
        to={{
          pathname: '/login',
          // search: window.location.search,
          search: queryString,
        }}
      >
        Sign In
      </Link>
    </section>
  </section>
);

export default SendResetLink;

SendResetLink.propTypes = {
  isRequestPending: PropTypes.bool,
  onSubmit: PropTypes.func,
  queryString: PropTypes.string,
};

SendResetLink.defaultProps = {
  isRequestPending: false,
  onSubmit: undefined,
  queryString: undefined,
};
