import 'babel-polyfill';
import 'scss/App.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from 'components/App';
import loadConfig from 'helpers/loadConfig';
import getFeature from 'helpers/getFeature';
import getAppsettings from 'helpers/getAppSettings';
import { receivedAwsCredentials } from 'redux-modules/auth/actions';
import store, { history } from './store';

loadConfig().then(async (data) => {

await getFeature().then((feature)=>{
  let getMobilePromptFeature = 0;
  let getPasswordComplexityFeature = 0;
  for (let index = 0; index < feature.length; index++) {
   if(feature[index].featureCode == "Password_Complexity_Rules")
    {
      getPasswordComplexityFeature=feature[index].isActive;
    }
    if(feature[index].featureCode == "Mobile_Prompt")
    {
      getMobilePromptFeature=feature[index].isActive;
    }
  }
    data.config.featurePasswordComplexity = getPasswordComplexityFeature;    
    data.config.featureMobilePrompt = getMobilePromptFeature;

}).catch((err) => {
  data.config.featurePasswordComplexity = 0;
  data.config.featureMobilePrompt = 0;
})

await getAppsettings().then((appSettings)=>{
  let getMobilePromptConfigurationDaysAppSetting = 0;
  let getVerificationCodeValidityPeriodAppSetting = 0;
  for (let index = 0; index < appSettings.length; index++) {
   if(appSettings[index].key == "MobilePromptConfigurationDays")
    {if (isNaN(parseInt(appSettings[index].value)))
      {
        if (!isNaN(parseInt(appSettings[index].defaultValue)))
        {
        getMobilePromptConfigurationDaysAppSetting=appSettings[index].defaultValue;
        }
      }      
      getMobilePromptConfigurationDaysAppSetting=appSettings[index].value;
    }
    if(appSettings[index].key == "VerificationCodeValidityPeriod")
    {if (isNaN(parseInt(appSettings[index].value)))
      {
        if (!isNaN(parseInt(appSettings[index].defaultValue)))
        {
          getVerificationCodeValidityPeriodAppSetting=appSettings[index].defaultValue;
        }
      }      
      getVerificationCodeValidityPeriodAppSetting=appSettings[index].value;
    }
  }
    data.config.appSettingMobilePromptConfigurationDays = parseInt(getMobilePromptConfigurationDaysAppSetting);
    data.config.appSettingVerificationCodeValidityPeriod = parseInt(getVerificationCodeValidityPeriodAppSetting);
    
}).catch((err) => {
  data.config.appSettingMobilePromptConfigurationDays = 0;
  data.config.appSettingVerificationCodeValidityPeriod = 0;
})

  store.dispatch(receivedAwsCredentials(data.config));
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App config={data.config} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
});
