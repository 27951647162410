import * as TYPES from './types';

export const initialState = {
  isLoadingCurrentUser: true,
  isPasswordVisible: false,
  isRequestPending: false,
  errorMessage: undefined,
  isForgotPasswordSent: false,
  isPasswordChangeSuccess: false,
  isUserIdChangeConfirmed: false,
  wasConfirmationCodeRedelivered: false,
  userArrivedSignedIn: false,
  promptForPhone: undefined,
  //recoveryError: false,
  //recoveryEmail: null,
  signInError: {},
  oauthError: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case TYPES.RR_LOCATION_CHANGE:
      return {
        ...initialState,
        isLoadingCurrentUser: state.isLoadingCurrentUser,
        user: state.user,
        aws: state.aws,
        promptForPhone: state.promptForPhone,
      };
    case TYPES.RECEIVED_AWS_CREDENTIALS:
      return {
        ...state,
        aws: payload,
      };
    case TYPES.AUTH_REQUEST_BEGIN:
      return {
        ...state,
        errorMessage: undefined,
        isRequestPending: true,
        isAuthRequestSuccess: false,
        wasConfirmationCodeRedelivered: false,
      };
    case TYPES.AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        completedRegisterStepOne: true,
        isAuthRequestSuccess: true,
        isRequestPending: false,
        errorMessage: undefined,
        user: {
          username: payload.username,
          userPoolId: payload.userPoolId,
          clientId: payload.clientId,
          verificationFlow: payload.verificationFlow,
          registrationFormValues: payload.registrationFormValues,
        },
      };
    case TYPES.AUTH_ERROR:
      console.log('error');
      return {
        ...state,
        errorMessage: payload,
        isRequestPending: false,
      };
    case TYPES.O_AUTH_ERROR:
      return {
        ...state,
        oauthError: payload,
        isRequestPending: false,
      }
      case TYPES.SIGNIN_ERROR:
      return {
        ...state,
        signInError: payload,
        isRequestPending: false,
      }
      //case TYPES.RECOVER_EMAIL_SUCCESS:
      //  return {
      //    ...state,
      //    recoveryEmail: payload,
      //    isRequestPending: false,
      //  }
      //case TYPES.RECOVERY_ERROR:
      //return {
      //  ...state,
      //  recoveryError: payload,
      //  isRequestPending: false,
      //}
    case TYPES.AUTH_REQUEST_COMPLETE:
      return {
        ...state,
        isRequestPending: false,
        isAuthRequestSuccess: true,
      };
    case TYPES.SET_ERROR:
      return {
        ...state,
        errorMessage: payload,
      };
    case TYPES.REGISTRATION_SUCCESS:
      return {
        ...state,
        errorMessage: undefined,
        completedRegistrationStepTwo: true,
      };
    case TYPES.TOGGLE_PASSWORD_VISIBILITY:
      return {
        ...state,
        isPasswordVisible: !state.isPasswordVisible,
      };
    case TYPES.SET_CURRENT_USER:
      return {
        ...state,
        user: {
          username: payload.username,
          email: payload.attributes.email,
          clientId: payload.pool.clientId,
          userPoolId: payload.pool.userPoolId,
          phone: payload.attributes.phone_number,
          given_name: payload.attributes.given_name,
          family_name: payload.attributes.family_name,
          isRegistered: true,
        },
      };
    case TYPES.UPDATE_USER_ATTRIBUTES:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case TYPES.SET_NO_USER:
      return {
        ...state,
        user: undefined,
      };
    case TYPES.LOADING_CURRENT_USER:
      return {
        ...state,
        isLoadingCurrentUser: true,
      };
    case TYPES.LOADED_CURRENT_USER:
      return {
        ...state,
        isLoadingCurrentUser: false,
      };
    case TYPES.SHOW_LOADER:
        return {
          ...state,
          showLoader: true,
        };
    case TYPES.HIDE_LOADER:
          return {
            ...state,
            showLoader: false,
          };
    case TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChangeSuccess: true,
        errorMessage: undefined,
      };
    case TYPES.FORGOT_PASSWORD_SENT:
      return {
        ...state,
        isForgotPasswordSent: true,
        forgotPasswordUsername: payload,
        errorMessage: undefined,
      };
    case TYPES.FORGOT_PASSWORD_SUBMIT_SUCCESS:
      return {
        ...state,
        forgotPasswordSubmitSuccess: true,
        errorMessage: undefined,
      };
    case TYPES.FORCE_RESET_PASSWORD_SUBMIT_SUCCESS:
        return {
          ...state,
          forceResetPasswordSubmitSuccess: true,
          errorMessage: undefined,
        };
    case TYPES.REDO_FORGOT_PASSWORD:
      return {
        ...state,
        isForgotPasswordSent: false,
        errorMessage: undefined,
      };
    case TYPES.CONFIRM_USER_ID_SUCCESS:
      return {
        ...state,
        isUserIdChangeConfirmed: true,
      };
    case TYPES.CHANGE_USER_ID_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          email: payload,
        },
        changeUserIdSuccess: true,
        errorMessage: undefined,
      };
    case TYPES.AUTH_CODE_RESENT:
      return {
        ...state,
        wasConfirmationCodeRedelivered: true,
      };
    case TYPES.USER_ARRIVED_SIGNED_IN:
      return {
        ...state,
        userArrivedSignedIn: true,
      };
    case TYPES.PROMPT_FOR_PHONE:
      return {
        ...state,
        promptForPhone: payload,
      };
    default:
      return state;
  }
};
