import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { calculateStrength } from './validate';

const PasswordTooltip = ({ target, password = '', setIsPasswordValid }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [rules, setRules] = useState([
        { id: 'minCharacters', rule: 'At least 8 characters', isValid: false },
        { id: 'uppercase', rule: 'One upper-case letter', isValid: false },
        { id: 'lowercase', rule: 'One lower-case letter', isValid: false },
        { id: 'oneNumber', rule: 'One number', isValid: false },
        { id: 'specialCharacter', rule: 'One special character (@$!%*?&#)', isValid: false },
    ]);

    useEffect(() => {
        const strength = calculateStrength(password || '');
        const newRules = [
            { id: 'minCharacters', rule: 'At least 8 characters', isValid: password.length >= 8 },
            { id: 'uppercase', rule: 'One upper-case letter', isValid: /[A-Z]/.test(password) },
            { id: 'lowercase', rule: 'One lower-case letter', isValid: /[a-z]/.test(password) },
            { id: 'oneNumber', rule: 'One number', isValid: /[0-9]/.test(password) },
            { id: 'specialCharacter', rule: 'One special character (@$!%*?&#)', isValid: /.*[.,<>:;|_~`'"!@#/\-?[\]\\(){}$%^&=+*]/.test(password) },
        ];

        setRules(newRules);
        if (strength === 5) {
            setIsPasswordValid && setIsPasswordValid(true);
            setTooltipOpen(false);
        } else if (password.length > 0) {
            setIsPasswordValid && setIsPasswordValid(false);
            setTooltipOpen(true);
        } else {
            setTooltipOpen(false);
        }
    }, [password]);

    const renderStrengthMeter = () => {
        const strength = calculateStrength(password);
        const strengthClass = classnames('password-strength', {
            'strength-weak': strength < 3,
            'strength-medium': strength >= 3 && strength < 5,
            'strength-strong': strength === 5,
        });

        return (
            <div className={strengthClass}>
                <div className="strength-bar" style={{ width: `${(strength / 5) * 100}%` }}></div>
            </div>
        );
    };

    return (
        <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={target}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            delay={{ show: 0, hide: 100 }}
            className="bg-white"
            trigger="manual"
            id="passwordTooltip"
        >
            {renderStrengthMeter()}
            <ul className="password-rules-list text-left pt-2">
                {rules.map((rule, index) => (
                    <li key={index} className="text-dark">
                        {rule.isValid ? (
                            <FontAwesomeIcon id="circleCheck" icon={faCircleCheck} className="text-success pr-2" />
                        ) : (
                                <FontAwesomeIcon id="circleXmark" icon={faCircleXmark} className="text-danger pr-2" />
                            )}
                        {rule.rule}
                    </li>
                ))}
            </ul>
        </Tooltip>
    );
};

PasswordTooltip.propTypes = {
    target: PropTypes.string.isRequired,
    password: PropTypes.string,
    setIsPasswordValid: PropTypes.func,
};

PasswordTooltip.defaultProps = {
    password: '',
    setIsPasswordValid: undefined,
};

export default PasswordTooltip;
