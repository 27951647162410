export const getUser = (state) => state.auth.user;
export const isRequestPending = (state) => state.auth.isRequestPending;
export const isPasswordVisible = (state) => state.auth.isPasswordVisible;
export const getErrorMessage = (state) => state.auth.errorMessage;
export const isForgotPasswordSent = (state) => state.auth.isForgotPasswordSent;
export const isAuthRequestSuccess = (state) => state.auth.isAuthRequestSuccess;
export const isPasswordChangeSuccess = (state) => state.auth.isPasswordChangeSuccess;
export const isLoadingCurrentUser = (state) => state.auth.isLoadingCurrentUser;
export const isChangeUserIdSuccess = (state) => state.auth.changeUserIdSuccess;
export const getForgotPasswordUsername = (state) => state.auth.forgotPasswordUsername;
export const getForgotPasswordSubmitSuccess = (state) => state.auth.forgotPasswordSubmitSuccess;
export const getForceResetPasswordSubmitSuccess = (state) => state.auth.forceResetPasswordSubmitSuccess;
export const showLoader = (state) => state.auth.showLoader;
export const getClientId = (state) => state.auth.aws.clientId;
export const getUserPoolId = (state) => state.auth.aws.userPoolId;
export const isRegistrationSuccess = (state) => state.auth.isRegistrationSuccess;
export const completedRegistrationStepTwo = (state) => state.auth.completedRegistrationStepTwo;
export const getOAuthError = (state) => state.auth.oauthError;
export const getIdentityBaseUrl = (state) => state.auth.aws.identityBaseUrl;
export const getPromptForPhone = (state) => state.auth.promptForPhone;
export const getSignInError = (state) => state.auth.signInError;
