/* eslint-disable no-console */

export function initializePendo(account) {
    // Check if user is signed in
    if (account) {
        const { firstName, lastName, hstreamId, emails } = account;

        // Use app name in absence of account info
        const appName = 'HLA';

        // Find the first verified email marked as an alias
        const match = emails.filter((item) => item.isAlias && item.verified).pop();
        const email = match ? match.value : null;

        // Create the signed-in pendo user object
        const pendoUser = {
            visitor: {
                id: hstreamId,
                email,
                name: `${firstName} ${lastName}`,
            },
            account: {
                id: appName,
                name: appName,
            },
        };

        // Identify the user once signed in
        if (window.pendo) {
            window.pendo.identify(pendoUser);
        } else {
            console.error('Pendo not found, unable to identify user', pendoUser);
        }
    } else {
        const appName = 'HLA';
        // Initialize Pendo with anonymous visitor ID on page load
        if (window.pendo) {
            window.pendo.initialize({
                visitor: {
                    id: 'VISITOR-UNIQUE-ID'
                },
                account: {
                    id: appName,
                    name: appName,
                },
            });
        } else {
            console.error('Pendo not found, unable to initialize anonymous visitor');
        }
    }
}

// Call initializePendo on page load with null to initialize an anonymous visitor
initializePendo(null);

