import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import PasswordInput from '../../core/PasswordInput';
import PasswordTooltip from '../../components/fields/PasswordTooltip';
import {
    required,
    matchesPassword,
    noRepeatingCharacters,
    noSequentialCharacters,
    notCommonPassword,
    noEndSpaces,
    minLength,
} from './validate';

const PasswordFieldComponent = ({
    input,
    meta,
    displayPassword,
    onToggle,
    disabled,
    label,
    confirm,
    showTooltip,
    setIsPasswordValid,
    featureToggle
}) => {


    return (
        <div className="password-input-wrapper">
            <PasswordInput
                id={input.name}
                displayPassword={displayPassword}
                onToggle={onToggle}
                disabled={disabled}
                errorMessage={(meta.touched && meta.error) || ''}
                label={label}
                data-private="redact"
                {...input}
            />
            {!featureToggle && showTooltip && (
                <PasswordTooltip
                    target={input.name}
                    password={input.value}
                    setIsPasswordValid={setIsPasswordValid}
            />
            )}
        </div>
    );
};

export const NAME_CONFIRM = 'confirmPassword';
export const NAME_PASSWORD = 'password';
export const NAME_CURRENT = 'currentPassword';

const PasswordField = ({
    disabled,
    displayPassword,
    onToggle,
    confirm,
    current,
    name,
    label,
    skipValidation,
    featureToggle,
    showTooltip,
    setIsPasswordValid
}) => {
    let validate = skipValidation
        ? [
            required, // The PW Field should always be required even if not validated
        ]
        : current ? [required] : featureToggle ? [required, minLength,noRepeatingCharacters, noSequentialCharacters, notCommonPassword, noEndSpaces] :
            [required, noRepeatingCharacters, noSequentialCharacters, notCommonPassword, noEndSpaces];
    if (confirm) {
        validate = [...validate, matchesPassword];
    }
    let fieldName = name;

    if (!fieldName) {
        fieldName = current ? NAME_CURRENT : confirm ? NAME_CONFIRM : NAME_PASSWORD;
    }

    let fieldLabel = label;

    if (!fieldLabel) {
        fieldLabel = current ? 'Current Password' : confirm ? 'Confirm Password ' : 'Password';
    }

    return (
        <Field
            confirm={confirm}
            current={current}
            name={fieldName}
            component={PasswordFieldComponent}
            validate={validate}
            disabled={disabled}
            displayPassword={displayPassword}
            onToggle={onToggle}
            label={fieldLabel}
            showTooltip={showTooltip}
            setIsPasswordValid={setIsPasswordValid}
            featureToggle={featureToggle}
        />
    );
};

PasswordField.propTypes = {
    disabled: PropTypes.bool,
    displayPassword: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    confirm: PropTypes.bool,
    current: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    skipValidation: PropTypes.bool,
    featureToggle: PropTypes.bool,
    setIsPasswordValid: PropTypes.func,
};

PasswordField.defaultProps = {
    disabled: false,
    displayPassword: false,
    confirm: false,
    current: false,
    name: undefined,
    label: undefined,
    skipValidation: false,
    featureToggle: false,
    setIsPasswordValid: undefined,
};

export default PasswordField;
export { PasswordFieldComponent };
